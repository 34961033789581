/* -------------------------------------------------------------------------------------------
* This styles come from compiled SCSS Mat Theme which you can get in repo:
* https://svn.celum.company/svn/Viper/platform/build-tools/com.celum.platform.styling.tools
* --------------------------------------------------------------------------------------------
*/
@import './custom-celum-theme/material-theme';

/* -------------------------------------------------------------------------------------------
* This styles is being imported to overwrite material theme styles to adjust them according
* to the Celum Design System
* --------------------------------------------------------------------------------------------
*/
@import './custom-celum-theme/components/autocomplete';
@import './custom-celum-theme/components/input';
@import './custom-celum-theme/components/button';
@import './custom-celum-theme/components/chips';
@import './custom-celum-theme/components/checkbox';
@import './custom-celum-theme/components/datepicker';
@import './custom-celum-theme/components/slide-toggle';
@import './custom-celum-theme/components/progress-bar';
@import './custom-celum-theme/components/expansion-panel';
@import './custom-celum-theme/components/radio';
@import './custom-celum-theme/components/sidenav';
@import './custom-celum-theme/components/tab-group';
@import './custom-celum-theme/components/table';
@import './custom-celum-theme/components/tooltip';
@import './custom-celum-theme/components/menu';

/** MATERIAL DESIGN OVERWRITES **/

mat-checkbox label .mat-checkbox-inner-container {
  cursor: pointer;
}

mat-checkbox.mat-checkbox-disabled label .mat-checkbox-inner-container {
  cursor: default;
}

/* make sure that placeholder text of drop down is not cut off at the bottom*/
.mat-mdc-form-field {
  min-height: 1.9rem;
}

/* placeholder text in inputs should be somewhat lighter colored */
.mat-form-field-label,
.mat-form-field-label.mat-focused {
  color: rgba(61, 64, 67, .7);
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-notched-outline__trailing {
  border-color: currentColor;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: currentColor;
}

.mdc-text-field--outlined .mdc-notched-outline {
  color: @color-blue-gray-200;
}

/* remove strange space after hint/error text */
.mat-form-field-hint-spacer {
  display: none;
}

/* set background color for activated toggle button */
.mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #bfead2 !important;
}

body .mat-mdc-menu-panel {
  min-height: 48px; // min height should be so that it is also okay to have just one entry
}

// remove primary color on selected item from mat-mdc-option, since it is not by our standards
body .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: currentColor;
}
