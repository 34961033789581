@import (reference, optional) "../../constants";

.mat-mdc-button-base {
  --mat-mdc-button-ripple-color: currentColor;
  --mat-mdc-button-persistent-ripple-color: currentColor;

  & .mat-ripple-element {
    opacity: 0.1;
  }

  &.mdc-button {
    --mdc-typography-button-font-size: 1.2rem;
    --mdc-typography-button-line-height: 20rem;
    --mdc-typography-button-letter-spacing: normal;
    --mat-mdc-button-ripple-color: currentColor;

    & .mdc-button__label {
      display: flex;
      align-items: center;
      flex-direction: row;
      white-space: nowrap;
      width: 100%;
      justify-content: center;
    }

    & .mat-mdc-button-touch-target {
      height: 100%;
    }

    &.celum-square-btn {
      padding: 0;
      min-width: 0;
      border-radius: 0.2rem;
      width: 2.8rem;
      height: 2.8rem;
      line-height: 2.8rem;
      color: @color-black;

      &--light-ripple:not([disabled]):hover {
        background-color: rgba(255, 255, 255, 0.08) !important;
      }

      &--active {
        background-color: fade(@color-blue-gray-800, 12);

        &.celum-square-btn--light-ripple {
          background-color: fade(white, 24);
        }
      }
    }

    &.celum-btn {
      text-transform: uppercase;
      line-height: 4rem !important;
      border-radius: 2px !important;
      padding: 0 1.6rem 0 1.6rem;

      &[disabled] {
        background-color: @color-blue-gray-200;
      }

      &--xs {
        line-height: 1.8rem !important;
        font-size: 1rem;
      }

      &--small {
        line-height: 3rem !important;
      }

      &--medium {
        line-height: 3.4rem !important;
      }

      &--large {
        line-height: 4.6rem !important;
      }

      .celum-icon {
        vertical-align: text-top;
        margin: 0 0.65rem 0 -0.3rem;
      }

      mat-spinner {
        display: inline-block;
        margin-right: 0.3rem;
        margin-top: 0;
      }

      &.mat-primary {
        .mat-progress-spinner circle, .mat-spinner circle {
          stroke: @color-white;
        }

        &:not([disabled]):hover {
          background-color: rgba(var(--colorPrimaryRgb), 0.8);
        }
      }

      &.mat-accent:not([disabled]):hover {
        background-color: fade(@color-accent, 80);
      }

      &.mat-warn:not([disabled]):hover {
        background-color: fade(@color-warning, 80);
      }

      .mat-progress-spinner circle, .mat-spinner circle {
        stroke: @color-primary;
      }
    }

    &:not([disabled]):hover .mat-mdc-button-persistent-ripple::before {
      opacity: 0.08 !important;
    }

    &:disabled {
      --mat-text-button-hover-state-layer-opacity: 0;
      --mat-text-button-pressed-state-layer-opacity: 0;
    }

    &:not(:disabled) {
      --mdc-text-button-label-text-color: @color-black;
    }
  }

  &.mdc-icon-button.mat-mdc-icon-button {
    mat-icon svg {
      width: unset;
      height: unset;
    }

    .mat-mdc-button-persistent-ripple::before {
      opacity: 0;
    }

    &.celum-round-btn--light-ripple.cdk-focused {
      opacity: 0.56;
    }

    &.celum-round-btn {
      --mdc-icon-button-icon-size: initial;
      padding: unset;
      opacity: 0.56;
      line-height: 3.6rem;
      width: 3.6rem;
      height: 3.6rem;
      color: @color-blue-gray-900;

      &.cdk-focused {
        opacity: 1;
      }

      &:hover:not(.celum-round-btn--disabled) {
        opacity: 0.75 !important;
      }

      &--active:not(.celum-round-btn--light-ripple) {
        opacity: 1;
      }

      &--active.celum-round-btn--light-ripple {
        opacity: 0.56;
      }

      &--light-ripple {
        opacity: 1;
        color: @color-white;
      }

      &--disabled {
        opacity: 0.36 !important;

        .celum-icon {
          cursor: default;
        }
      }

      .celum-icon {
        margin-top: -0.25rem;
      }

      mat-icon {
        font-size: 1.2rem;
      }

      & .mat-mdc-button-touch-target {
        width: unset;
        height: unset;
      }
    }
  }
}

.mat-mdc-icon-button.mat-mdc-button-base {
  height: 3.2rem;
  padding: 0.2rem 0 0 0;
}

//see https://github.com/angular/components/issues/26179
.mat-primary, .mat-accent, .mat-warn {
  &.mat-mdc-button-base {
    --mat-fab-foreground-color: #fff;
    --mdc-filled-button-label-text-color: #fff;
    --mdc-protected-button-label-text-color: #fff;
  }

  &.mat-mdc-fab,
  &.mat-mdc-mini-fab {
    --mat-icon-color: #fff;
  }
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: auto;
}

