@import (reference, optional) "../../constants";

// normally, this would be done automatically by angular material as they try to add transparency. It seems like this is not possible with css-variables for now
// so we have to manually overwrite this. Might be re-visited when they switch to CSS variables which they now can and will do as IE11 support is dead
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(var(--colorPrimaryRgb), 0.3);
}

// we have a different height than standard so we need to adapt this in order that the date-icon is still centered vertically
mat-datepicker-toggle {
  display: flex;
}
