@import (reference, optional) '../../constants';

:root {
  input::-webkit-input-placeholder {
    line-height: normal !important;
  }

  .celum-inline-input {
    &.mat-mdc-form-field.mat-focused .mdc-notched-outline {
      background-color: unset !important;
    }

    &--required {
      &:before {
        content: '*';
        color: @color-orange;
        position: absolute;
        left: -1rem;
        top: 0.2rem;
        z-index: 10;
      }
    }
  }

  textarea.mat-mdc-input-element {
    padding: 0.6rem 0 0.6rem 0;
    min-height: 3rem;
  }

  .celum-inline-input:not(.mat-focused) {
    .mdc-notched-outline > * {
      border-color: transparent !important;
    }

    .celum-inline-form-field-buttons {
      visibility: hidden !important;
    }

    &:hover {
      .celum-inline-form-field-buttons {
        visibility: visible !important;
      }
    }
  }

  .celum-input-form-field {
    margin-top: 0.3rem;
    --mdc-shape-small: 3px; // border radius
    margin-bottom: 0.1rem;
    height: auto;
    box-sizing: content-box;

    .mat-mdc-form-field-bottom-align::before {
      height: 1rem;
    }

    // remove size settings so we can have our custom sizes
    .mat-mdc-form-field-infix {
      min-height: unset;

      padding-left: unset;
      padding-right: unset;
      padding-top: 1rem;
      padding-bottom: 1rem;

      display: flex;
      align-items: center;

      textarea {
        padding: 0;
      }
    }

    .mat-mdc-form-field-icon-suffix {
      display: flex;
      align-items: center;
    }

    .mdc-floating-label {
      margin-left: 0.5em;
      top: 50%;
      color: rgba(38, 50, 56, 0.42);

      &--float-above {
        top: 28px;
        margin-left: unset;
      }
    }

    // specify default padding on all form fields
    .mat-mdc-text-field-wrapper {
      padding: 0 1rem;
      flex: initial;
    }

    .mat-mdc-form-field-icon-suffix,
    .mat-mdc-form-field-suffix {
      line-height: 0;
      display: flex;
      align-items: center;
    }


    &.mat-mdc-form-field-disabled {
      pointer-events: none;
    }

    input,
    textarea {
      line-height: 1.3 !important;
      color: currentColor;

      &::placeholder {
        color: rgba(38, 50, 56, 0.42) !important;
      }
    }

    .mdc-notched-outline {
      color: @color-blue-gray-200;
      border-radius: 3px;
    }

    .mdc-notched-outline > * {
      border-width: 1px !important;
    }

    .mat-mdc-form-field-subscript-wrapper {
      font-size: 75%;
      color: rgba(@color-black, 0.56);

      .mat-mdc-form-field-hint-wrapper,
      .mat-mdc-form-field-error-wrapper {
        padding: 0;
      }
    }

    &.mat-focused {
      // another way to set border-color to @color-blue-gray-600, because if we set border color with !important, we cannot change it anywhere in app
      --mdc-outlined-text-field-focus-outline-color: @color-blue-gray-600;
      --mdc-outlined-text-field-error-focus-outline-color: @color-blue-gray-600;

      .mat-mdc-floating-label {
        color: @font_color-black;
      }

      .mat-mdc-form-field-infix .mat-mdc-input-element {
        caret-color: @color-blue-gray-600;
      }
    }

    &:not(.mat-focused):not(.mat-form-field-invalid):not(.mat-form-field-disabled):hover {
      .mdc-notched-outline {
        background-color: fade(@color-black, 8);
      }

      &:not(.celum-inline-input) .mdc-notched-outline > * {
        border-color: @color-blue-gray-200 !important;
      }
    }

    &--autosize {
      .mat-mdc-form-field-infix {
        height: unset !important; // autosize fields can not have a fixed size like inputs

        textarea {
          box-sizing: content-box; // with box-sizing: border-box scrollbars appear, see https://github.com/angular/components/issues/12902
        }
      }
    }

    .generateInput(3.4rem);

    &--xs {
      .generateInput(1.1rem);

      margin-top: 0;
      --mdc-shape-small: 2px;
      margin-bottom: 0;

      &.mat-mdc-form-field {
        min-height: 1.4rem;
      }

      & .mat-mdc-text-field-wrapper {
        padding-left: 0.4rem;
        padding-right: 0.4rem;
      }

      & .mat-mdc-form-field-infix {
        padding-top: 0.05rem;
        padding-bottom: 0.05rem;

        textarea {
          padding: 0;
          font: @font-caption;
          min-height: 1.1rem !important;
        }
      }
    }

    &--small {
      .generateInput(3rem);

      & .mat-mdc-form-field-infix {
        padding-top: 0.05rem;
        padding-bottom: 0.05rem;

        textarea {
          padding: 0.2rem 0 0.3rem 0;
        }
      }
    }

    &--emphasized {
      .generateInput(4rem);

      .mat-mdc-input-element {
        font: @font-title;
        line-height: 1.3 !important;
        font-weight: bold;
      }

      .mat-mdc-input-element::placeholder {
        font: @font-body;
        transform: translateY(-0.2rem);
      }

      & .mat-mdc-form-field-infix {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }
    }

    &--large {
      .generateInput(4rem);

      & .mat-mdc-form-field-infix {
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
      }
    }

    &--xl {
      .generateInput(6.8rem);
      min-width: 31rem;

      // overwrite default padding
      .mat-mdc-text-field-wrapper {
        padding: 0 2.3rem;
      }

      .mat-mdc-form-field-label {
        top: 0.9em;
        font-size: 3rem;
      }

      .mat-mdc-input-element {
        font-size: 3rem;
      }

      & .mat-mdc-form-field-infix {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
      }
    }

    &:not(&--reserve-hint-space) {
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    }

    &--light-substrate:not(.celum-input-form-field--white-bg) {
      color: @color-white;

      .mat-mdc-input-element {
        caret-color: @color-white !important;
        color: @color-white;

        &::placeholder {
          color: fade(@color-white, 56) !important;
        }
      }

      .mdc-notched-outline > * {
        border-color: @color-white !important;
        border-width: 0.1rem;
      }

      &:hover {
        .mdc-notched-outline {
          background-color: fade(@color-black, 8);
        }

        &:not(.celum-inline-input) .mdc-notched-outline > * {
          border-color: @color-white !important;
        }

      }

      &.mat-focused {
        .mdc-notched-outline {
          background-color: transparent !important;
        }
      }
    }

    &--white-bg {
      &:hover:not(.mat-focused) {
        .mdc-notched-outline {
          background-color: @color-blue-gray-050 !important;
        }
      }

      .mdc-notched-outline {
        z-index: unset;
        background-color: @color-white !important;
      }
    }
  }

  .generateInput(@input-height) {
    .mat-mdc-form-field-infix {
      min-height: @input-height;
    }

    .mat-mdc-form-field-icon-suffix {
      top: unset;
    }
  }
}
