@import (reference, optional) "../../constants";

:root {
  .mdc-tab__ripple::before,
  .mat-mdc-tab .mat-ripple-element,
  .mat-mdc-tab-header-pagination .mat-ripple-element,
  .mat-mdc-tab-link .mat-ripple-element {
    background-color: transparent;
  }

  .mat-mdc-tab-labels {
    background-color: transparent;

    .mdc-tab-indicator--active .mdc-tab__text-label {
      color: @color-blue-gray-900;
    }
  }

  .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
    border-color: @color-blue-gray-900;
  }

  .mat-mdc-tab-group.celum-secondary-tabs {
    .mat-mdc-tab-header {
      border: none;
    }

    .mat-mdc-tab-labels .mdc-tab__text-label {
      text-transform: none;
      color: @color-blue-gray-900;
    }

    .mat-mdc-tab-labels .mat-mdc-focus-indicator {
      min-width: unset;
      height: 2.7rem;
      padding: 0;
      margin: 0 0.8rem;
      text-transform: none;
      font-size: 1.4rem;

      & .mat-mdc-tab-label-content {
        font-weight: normal;
      }

      &:first-child {
        margin: 0 0.8rem 0 0;
      }

      &:last-child {
        margin: 0 0 0 0.8rem;
      }

      // no ripple for secondary tabs
      & .mat-mdc-ripple-element {
        visibility: hidden;
      }
    }

    .mat-mdc-tab-labels .mat-mdc-tab-label-active .mat-mdc-tab-label-content {
      font-weight: 600;
    }
  }
}
