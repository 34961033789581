@import (reference, optional) "../../constants";

mat-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: @color-blue-gray-800 !important;
}

mat-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: @color-success !important;
}

mat-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: @color-error !important;
}
